import Link from 'next/link';
import { SEO } from '../common/components/Seo';
import { ChevronDoubleDownIcon, LibraryIcon } from '@heroicons/react/outline';
import { CarouselTrustBox } from '@components/TrustPilot';
import { ReadyToGetBonded } from '@components/CTA/ReadyToGetBonded';
import { CompanyPerks } from '@components/CompanyPerks';
import { FAQ } from '@components/FAQ';
import { getGenericConnectUrl } from '@utils/helpers';
import { getSiteData } from 'data/bootstrapData';
import { StructuredRenderer } from '@components/StructuredRenderer';
const { request } = require('data/datocms/request');
export default function Home(props) {
  return (
    <>
      <SEO
        title={props.homePage.seoTags.title}
        description={props.homePage.seoTags.description}
      />
      <div className="w-full h-full bg-white relative flex flex-col items-center px-4 sm:pb-32 pb-16 pt-8 sm:pt-20 justify-between">
        <div className="flex max-w-screen-lg flex-col items-center font-brand">
          <h1 className="md:text-6xl text-4xl leading-none sm:text-5xl tracking-tight font-bold text-center text-gray-900">
            <span className="block text-center text-lg sm:text-3xl">
              ContractorBonds
              <small className="font-semibold tracking-normal sm:text-lg text-sm">
                .com
              </small>
            </span>
            <span className="inline">Contractor surety bonds</span>
            <span className="inline">
              {' '}
              in as little as{' '}
              <span className="text-blue-surety">two minutes.</span>
            </span>
          </h1>
          <p className="mt-3 hidden sm:block text-center text-lg sm:text-2xl font-light text-gray-400">
            We make getting a contractor bond as straightforward and convenient
            as possible.
          </p>
          <div className="sm:mt-20 mt-10 flex flex-col shadow-md bg-white items-center border rounded-lg font-brand p-20  relative w-full max-w-prose">
            <h2 className="sm:text-xl text-lg  absolute font-brand tracking-tight -top-4 bg-white px-5 font-semibold ">
              What type of bond do you need?
            </h2>
            <div className="flex flex-col items-center w-full space-y-6">
              <div className="flex sm:flex-row flex-col text-xl w-full items-center space-x-0 space-y-3 sm:space-y-0 sm:space-x-3">
                <a
                  href={getGenericConnectUrl()}
                  className="py-3 w-full rounded text-center flex-1 font-bold bg-blue-surety text-white"
                >
                  Project
                </a>
                <Link
                  href="/find/select-state"
                  className="py-3 w-full rounded flex-1 text-center font-bold bg-blue-100 text-blue-surety"
                >
                  License
                </Link>
              </div>
            </div>
          </div>
          <Link
            href="#ready-to-get-bonded"
            className="flex flex-col mt-8 font-light text-lg transform translate-y-0 hover:-translate-y-1 transition-all hover:drop-shadow  hover:text-gray-900"
          >
            I'm Not Sure
            <ChevronDoubleDownIcon className="mt-1 h-3.5" />
          </Link>
        </div>
      </div>
      <div className="w-full bg-gray-50 border-t border-b py-8 sm:py-16">
        <CarouselTrustBox />
      </div>
      <div className="w-full  bg-white">
        <div className="py-32 px-4 sm:px-8">
          <CompanyPerks />
        </div>
      </div>

      <div className="w-full bg-white font-brand">
        <div className="w-full max-w-screen-lg flex flex-col items-center  mx-auto pb-32 px-4">
          <div className="h-10 w-10 mb-5 bg-gradient-to-br rounded-lg shadow-double-blue from-blue-500 to-blue-700 text-white flex items-center justify-center">
            <LibraryIcon className="w-6 h-6 stroke-current rounded-lg" />
          </div>
          <h2 className="sm:text-4xl text-2xl  text-center leading-snug font-bold tracking-wide mb-3">
            Learn About Contractor Bonds
          </h2>
          <p className="text-center sm:text-lg text-base mb-20 text-gray-600">
            Learn about Contractor Bonds from the industries experts.
          </p>
          <div className="grid font-brand md:grid-cols-2 grid-cols-1  lg:grid-cols-2 gap-10 w-full">
            {props.linkedPages.map((page) => {
              return (
                <Link
                  className="flex flex-col items-center sm:text-left text-center sm:items-start cursor-pointer transform transition-all translate-y-0 hover:-translate-y-1"
                  key={page.slug}
                  href={page.slug}
                >
                  <div className="text leading-snug font-semibold tracking-wide uppercase text-blue-surety">
                    {page.kicker}
                  </div>
                  <h3 className="sm:text-2xl text-lg leading-relaxed mb-3 text-gray-900 font-semibold">
                    {page.title}
                  </h3>
                  <p className="sm:text-lg text-base text-gray-500">
                    <StructuredRenderer data={page.blurb}></StructuredRenderer>
                  </p>
                  <button
                    className="mt-3 flex flex-row sm:justify-start justify-center items-center text-blue-400 underline text-base sm:text-lg font-medium"
                    href="#"
                  >
                    See More
                  </button>
                </Link>
              );
            })}
          </div>
        </div>
        <ReadyToGetBonded></ReadyToGetBonded>
        <div className="bg-white font-brand w-full border-t px-4">
          <FAQ />
        </div>
      </div>
    </>
  );
}

export async function getStaticProps() {
  const siteData = await getSiteData();

  const linkedPages = siteData.pagesTable.filter(
    (p) =>
      p.slug === 'what-is-a-contractor-bond' ||
      p.slug === 'how-much-do-contractor-bonds-cost'
  );

  //sort array (descending Z-A) to ensure definition is always before cost
  linkedPages.sort((a, b) => {
    if (a.slug < b.slug) {
      return 1;
    }
    if (a.slug > b.slug) {
      return -1;
    }
    return 0;
  });

  const { homePage } = await request({
    query: `#graphql
      query {
        homePage {
          seoTags {
            title
            description
          }
        }
      }
    
    `,
  });

  return {
    props: { linkedPages, homePage },
  };
}
